.landing-page-container {
    display:flex;
    height: 100%;
    width: 100%;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
}

.text-container {
    height: 50%;
    width: 60%;
    display: flex;
    flex-direction: column;
}

.name {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 0px 6px 0px;
}

.position {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 0px 6px 0px;
}

.about-me {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 0px 6px 0px;
}
