.projects-title{
    font-family: Arial, Helvetica, sans-serif;
    margin: 2% 2% 2% 5%;
}
.project-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    padding: 5% 5% 5% 5%;
    margin: 5% 5% 5% 5%;
}

.card-image {
    width: 315px;
    height: 250px;
}

.card-text{
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 451px) {
    .projects-title{
        margin: 1% 2% 2% 2%;
    }
    .projects-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-card-container {
        width: 60%;
        flex-direction: row;
        justify-content: space-evenly;
        border-radius: 25px;
        padding: 1% 1% 1% 0%;
        margin-top: 0px;
    }

    .card-text {
        width: 80%;
        overflow: hiddens;
        text-overflow: ellipsis;
    }

    .card-button {
        justify-content: center;
    }
}