.contact-container {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 5%;
    padding: 2% 0px 2% 0px;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    font-family: Arial, Helvetica, sans-serif;
}

.contact-me-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
}

.links-container {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
}

.contact-link-text {
    text-decoration: none;
}

@media screen and (min-width: 451px) {

    .contact-container{
        width: 50%;
    }
    
}