.about-me-card-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    font-family: Arial, Helvetica, sans-serif;

}
.card-container{
    margin-bottom: 5%;
}
.card-image {
    width: 256px;
    height: 256px;
    padding: 5% 5% 5% 5%;
}
.resume-container{
    width: 100%;
    height: 481px;
}
.resume-link-container{
    margin-bottom: 2%;
}
.resume-link{
    text-decoration: none;
    margin-left: 2%;
}
.resume{
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 451px) {
    .about-me-card-container  {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
    }
    
    .card-container{
         margin-right: 5%; 
    }
    .resume-container{
        display: flex;
        width: 100%;
        height: 1320px;
        flex-direction: column;
        justify-content: center;
    }
    .resume-link-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 1%;
    }
    .resume-link{
        width: 60%;
        align-self: center;
        font-size: 1.875em;
    }
    .resume{
        width: 60%;
        align-self: center;
    }
}